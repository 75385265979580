import "react-app-polyfill/stable";
import "core-js";
import { createRoot } from "react-dom/client";
import React, { useState, useEffect } from "react";
import App from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { GlobalProvider } from "./GlobalContext";

const Root = () => {
  const [reCaptchaKey, setReCaptchaKey] = useState("");
  useEffect(() => {
    const fetchReCaptchaKey = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/settings`);
        if (data.length > 0) {
          const key = data[0].recaptcha_siteKey;
          setReCaptchaKey(key);
          console.log("Fetched reCAPTCHA Key:", key);  // Log the key to verify
        }
      } catch (error) {
        console.error("Error fetching reCaptcha key:", error);
      }
    };
  
    fetchReCaptchaKey();
  }, []);

console.log(reCaptchaKey,'reCaptchaKey')

  return (
    <Provider store={store}>
    <GlobalProvider>
      {reCaptchaKey ? (
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <App />
        </GoogleReCaptchaProvider>
      ) : (
        <App />
      )}
    </GlobalProvider>
  </Provider>
  );
};

window.addEventListener("DOMContentLoaded", function (e) {
  createRoot(document.getElementById("root")).render(<Root />);
});
